import { useMemo } from "react";

import { useCurrentWorkspace } from "@/api/workspace.ts";
import { ResourceCardType } from "@/assets/constants/constants.ts";
import GeneralProjectIcon from "@/components/library/v3/general-project-icon.tsx";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion.tsx";
import { ProjectButtonList } from "@/components/v3";
import type { ProjectIconsEnum } from "@/types/schemas";
import { useCurrentProjectId } from "@/utils";

const ProjectSelectorAccordion = () => {
  const { data: currentWorkspace } = useCurrentWorkspace();
  const currentProjectId = useCurrentProjectId();
  return useMemo(() => {
    return (
      <section className="flex-1">
        {currentWorkspace?.projects
          .filter(
            (item) =>
              item.directChildElements.filter((item) => item.elementType === ResourceCardType.DOCUMENT_ELEMENT).length >
              0,
          )
          .map((project) => {
            const defaultProps = project.id === currentProjectId ? "item-1" : "";
            return (
              <Accordion
                collapsible
                className="w-full border-0"
                defaultValue={defaultProps}
                key={project.id}
                type="single"
              >
                <AccordionItem className="border-0 px-3" value="item-1">
                  <AccordionTrigger className="flex-row-reverse justify-end gap-2 overflow-hidden border-0 px-2 py-2 text-sm font-bold text-primary">
                    <span className="ml-auto h-4 w-4">
                      <GeneralProjectIcon
                        className="h-4 w-4"
                        color={project.colorTag}
                        projectIcon={project.projectIcon as ProjectIconsEnum}
                      />
                    </span>
                    <span className="overflow-hidden truncate">{project.name}</span>
                  </AccordionTrigger>
                  <AccordionContent className="px-0 [&_section]:py-2" containerClassName="gap-1 flex flex-col">
                    <ProjectButtonList projectId={project.id} />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            );
          })}
      </section>
    );
  }, [currentProjectId, currentWorkspace?.projects]);
};
export default ProjectSelectorAccordion;
