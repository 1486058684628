import { useMemo } from "react";

import { NavLink } from "react-router-dom";

import { useSubscription } from "@/api/resources";
import { useCheckIfOldSubscription } from "@/api/subscription.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { CheckMarkIcon, CurlyArrow, EnvelopeSwoshIcon, Slash, Spinner, UpgradeToProIcon } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { useIsCountryAllowed } from "@/service/user.ts";
import { type PlanDescription } from "@/types/schemas";
import { cn } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

import FeatureList from "../v3/subscription/feature-list.tsx";

const PlanCard = ({
  plan,
  period,
  className,
  onPlanClick,
  portalCreateHandler,
  isLoading = false,
  showArrow = false,
  highlighted = false,
  buttonPosition = "top",
}: {
  plan: PlanDescription;
  isLoading?: boolean;
  period: string;
  className?: string;
  buttonPosition?: "top" | "bottom";
  onPlanClick: (plan: PlanDescription) => void;
  portalCreateHandler: () => void;
  showArrow?: boolean;
  highlighted?: boolean;
}) => {
  const { data: userCurrentPlan } = useSubscription();
  const firebaseRoleName = plan.firebaseRole;
  const isFreePlan = userCurrentPlan?.subscriptionPlan === "free";
  const isCountryAllowed = useIsCountryAllowed();
  const currentCurrencySymbol = plan.currency === "eur" ? "€" : "$";
  const [, setCancellationModalOpen] = useModalState(ModalKeys.CANCELLATION);
  const { data: isOldSubscription } = useCheckIfOldSubscription();

  const actionButton = useMemo(() => {
    if (firebaseRoleName === "free")
      return isOldSubscription?.isOld ? (
        <Button
          className="flex w-full gap-2"
          disabled={isFreePlan}
          variant="secondary"
          onClick={() => {
            setCancellationModalOpen(true);
          }}
        >
          {isFreePlan ? (
            <>
              Your current plan <CheckMarkIcon />
            </>
          ) : (
            "Ask for cancellation"
          )}
        </Button>
      ) : (
        <Button
          className="flex w-full gap-2"
          disabled={isFreePlan}
          variant="secondary"
          onClick={() => {
            portalCreateHandler();
          }}
        >
          {isFreePlan ? (
            <>
              Your current plan <CheckMarkIcon />
            </>
          ) : (
            "Cancel subscription"
          )}
        </Button>
      );
    if (firebaseRoleName === "pro")
      return (
        <Button
          className={cn("flex w-full gap-2")}
          disabled={isLoading || userCurrentPlan?.subscriptionPlan === "pro"}
          onClick={() => {
            if (userCurrentPlan?.subscriptionPlan !== "free" && userCurrentPlan?.period !== "yearly") {
              portalCreateHandler();
            } else {
              onPlanClick(plan);
            }
          }}
        >
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              {isFreePlan ? (
                <>
                  {" "}
                  {isCountryAllowed ? "Subscribe to Pro" : "Upgrade to Pro"} <UpgradeToProIcon />{" "}
                </>
              ) : (
                <>
                  {userCurrentPlan?.period === "monthly" ? (
                    <>
                      Your current plan <CheckMarkIcon />
                    </>
                  ) : (
                    <>
                      Downgrade to monthly <UpgradeToProIcon />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Button>
      );
    else if (firebaseRoleName === "pro_yearly")
      return (
        <Button
          className={cn("flex w-full gap-2")}
          disabled={isLoading || userCurrentPlan?.subscriptionPlan === "pro_yearly"}
          onClick={() => {
            if (userCurrentPlan?.subscriptionPlan !== "free" && userCurrentPlan?.period !== "monthly") {
              portalCreateHandler();
            } else {
              onPlanClick(plan);
            }
          }}
        >
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              {isFreePlan ? (
                <>
                  {" "}
                  {isCountryAllowed ? "Subscribe to Pro" : "Upgrade to Pro"} <UpgradeToProIcon />{" "}
                </>
              ) : (
                <>
                  {userCurrentPlan?.period === "yearly" ? (
                    <>
                      Your current plan <CheckMarkIcon />
                    </>
                  ) : (
                    <>
                      Upgrade to yearly <UpgradeToProIcon />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Button>
      );

    return (
      <NavLink className="w-full" to="mailto:hello@quino.ai">
        <Button className="flex w-full gap-2" variant="secondary">
          Contact us <EnvelopeSwoshIcon />
        </Button>
      </NavLink>
    );
  }, [
    userCurrentPlan?.subscriptionPlan,
    firebaseRoleName,
    isCountryAllowed,
    isFreePlan,
    isLoading,
    onPlanClick,
    plan,
    portalCreateHandler,
    userCurrentPlan?.period,
    setCancellationModalOpen,
    isOldSubscription?.isOld,
  ]);

  return (
    <div
      className={cn(
        "flex h-full flex-col gap-8 rounded-2xl px-6 py-8 data-[highlighted=true]:relative data-[highlighted=true]:border data-[highlighted=true]:border-stroke-primary-onBg data-[highlighted=true]:bg-pro-plan-radial-gradient data-[highlighted=true]:shadow-elevation-2",
        className,
      )}
      data-highlighted={highlighted}
    >
      <div
        className="absolute left-1/2 top-[-14px] hidden translate-x-[-50%] rounded-full bg-default px-3 py-2 text-label-l4 font-bold text-white data-[highlighted=true]:block"
        data-highlighted={highlighted}
      >
        MOST POPULAR
      </div>
      <div
        className="absolute right-[-0.73px] top-[-18px] hidden data-[show-arrow=true]:block"
        data-show-arrow={showArrow}
      >
        <CurlyArrow />
      </div>
      <div className="flex flex-col items-center gap-6">
        <div className="flex-col items-center gap-1">
          <h4 className="truncate text-center text-h4 font-semibold text-primary-onBg">{plan.title}</h4>
          <p className="truncate text-center text-base font-normal text-secondary-onBg">{plan.subTitle}</p>
        </div>
        {plan.priceId === "enterprise" ? (
          <h2 className="relative text-center text-h1 text-primary-onBg">Custom</h2>
        ) : (
          <h2 className="relative text-center text-h1 text-primary-onBg">
            {currentCurrencySymbol}
            {plan.price}
            <span
              className="absolute -right-10 -top-2 text-xl text-decorative-red data-[hidden=true]:hidden"
              data-hidden={period === "monthly" || plan.monthlyPrice === 0}
            >
              {currentCurrencySymbol}
              {plan.monthlyPrice}
              <Slash className="absolute -right-1 top-1" />
            </span>
          </h2>
        )}

        <p className="-mt-6 text-xs font-normal text-secondary-onBg">{plan.description}</p>
        {buttonPosition === "top" && actionButton}
      </div>
      <FeatureList plan={plan} />
      {buttonPosition === "bottom" && actionButton}
    </div>
  );
};

export default PlanCard;
