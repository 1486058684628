import { useCallback, useEffect, useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { useLiveQuery } from "dexie-react-hooks";
import { ref as storageRef } from "firebase/storage";
import { type DropzoneOptions, useDropzone } from "react-dropzone";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { useLocalStorage } from "usehooks-ts";

import { fetchDocumentDataDemo } from "@/api/demo";
import { fetchDocumentData, fetchTemporaryDocumentData } from "@/api/document.ts";
import { useProjectProcessingStatus } from "@/api/project";
import { useFiles, useSubscription } from "@/api/resources.ts";
import { ResourceCardType } from "@/assets/constants/constants.ts";
import { IDS } from "@/assets/constants/demo.ts";
import { BASE_DOCUMENT_SIZE_LIMIT, MEGABYTE } from "@/assets/constants/document.ts";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { ACCEPTED_FORMATS, ACCEPTED_FORMATS_FREE } from "@/assets/constants/user.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { docStorage, temporaryDocStorage } from "@/firebase";
import { documentDb, type FileInIndexedDb } from "@/service/dexie.ts";
import { useIsDemoLikePage, useIsTemporaryPage } from "@/service/hooks/misc.ts";
import {
  useCurrentDocumentTabEntity,
  useCurrentDocumentTabId,
  useCurrentWorkspaceId,
  useDocumentChatSessionId,
  useIsDocumentPage,
} from "@/service/hooks/react-router.ts";
import { type DocumentDetailsSchema, type DocumentProgress, type ProjectDocumentRedisTicker } from "@/types/schemas";
import { decideError } from "@/utils";
import useAppStateStore from "@/zustand/store";

export const useIsAnyUnderProcessing = (projectId: string) => {
  return useProjectProcessingStatus({ projectId });
};

export const isProcessingFinished = (docProgress: ProjectDocumentRedisTicker | undefined) => {
  if (!docProgress) return false;

  return (
    docProgress.dbWritingDone &&
    docProgress.textractionDone &&
    docProgress.documentHealthcheckDone &&
    docProgress.documentDownloaded &&
    docProgress.textEmbeddingDone
  );
};

export const isError = (docProgress: ProjectDocumentRedisTicker | undefined) => {
  if (!docProgress) return false;
  return docProgress.errorMessage !== null;
};

function toPercentageValue(docProgress: ProjectDocumentRedisTicker | undefined): number | undefined {
  if (docProgress === undefined) return undefined;

  const documentDownload = docProgress.documentDownloaded ? 1 : 0;
  const documentHealthcheckDone = docProgress.documentHealthcheckDone ? 1 : 0;
  const textraction = docProgress.textractionDone ? 1 : 0;
  const semanticEncoding = docProgress.textEmbeddingDone ? 1 : 0;
  const paragraphAggregationDone = docProgress.paragraphAggregationDone ? 1 : 0;
  const documentMetadataGatheringDone = docProgress.documentMetadataGatheringDone ? 1 : 0;
  const dbWriting = docProgress.dbWritingDone ? 1 : 0;
  const topicGenerationDone = docProgress.topicGenerationDone ? 1 : 0;

  const NUMBER_OF_ENTRIES = 8;

  return (
    ((documentDownload +
      documentHealthcheckDone +
      textraction +
      semanticEncoding +
      dbWriting +
      paragraphAggregationDone +
      topicGenerationDone +
      documentMetadataGatheringDone) /
      NUMBER_OF_ENTRIES) *
    100
  );
}

export const useDocumentProgress = (documentId: string | undefined): DocumentProgress | undefined => {
  const status = useAppStateStore((state) => (documentId ? state.docProcessing[documentId] : undefined));
  return useMemo<DocumentProgress | undefined>(() => {
    const processing = status ? !isProcessingFinished(status) : false;
    return {
      processing: processing,
      percentage: toPercentageValue(status),
      error: decideError(status),
    };
  }, [status]);
};

interface DocuStorageState {
  documentOnly: boolean;
}

export const useChatDocuConfig = (): [DocuStorageState, (params: DocuStorageState) => void] => {
  const [sessionId] = useDocumentChatSessionId();
  const [state, setState] = useLocalStorage<Record<string, DocuStorageState>>("CHAT-DOCU-SETTINGS", {
    "OPEN-NEW": { documentOnly: true },
  });
  const currentState = useMemo(() => {
    if (Object.hasOwn(state, sessionId)) return state[sessionId];
    if (Object.hasOwn(state, "OPEN-NEW")) return state["OPEN-NEW"];
    return { documentOnly: true };
  }, [state, sessionId]);
  const setCurrentState = useCallback(
    (params: DocuStorageState) => {
      if (sessionId) {
        setState((prev) => ({ ...prev, [sessionId]: { ...params } }));
      } else {
        setState((prev) => ({ ...prev, ["OPEN-NEW"]: { ...params } }));
      }
    },
    [sessionId, setState],
  );
  return [currentState, setCurrentState];
};

export const useFirstNote = () => {
  const { data: files } = useFiles();
  return useMemo(
    () => files?.filter(({ elementType }) => elementType === ResourceCardType.NOTE_ELEMENT).at(0),
    [files],
  );
};

export const useGetCachedDocumentUrl = ({
  userId,
  selectedProject,
  documentId,
  inheritedUrl,
}: {
  selectedProject: string;
  userId: string;
  documentId: string;
  inheritedUrl?: string | null;
}) => {
  const { matched } = useIsTemporaryPage();

  const ref = useMemo(() => {
    if (matched) return storageRef(temporaryDocStorage, documentId);

    return storageRef(docStorage, inheritedUrl ?? `/${selectedProject}/${userId}/${documentId}`);
  }, [documentId, inheritedUrl, matched, selectedProject, userId]);

  const [firebaseFileUrl, firestorageUrlLoading] = useDownloadURL(ref);

  const [indexedStorageFirstLoading, setIndexedStorageFirstLoading] = useState(true);
  const [indexedStorageLoading, setIndexedStorageLoading] = useState(false);

  const fileData = useLiveQuery<FileInIndexedDb | undefined>(
    async () => {
      const result = await documentDb.documents.where("id").equals(documentId).first();
      setIndexedStorageFirstLoading(false);
      return result;
    },
    // specify vars that affect query:
    [documentId, setIndexedStorageFirstLoading, documentDb],
  );

  const fileDataUrl = useMemo(() => {
    if (fileData) {
      return URL.createObjectURL(fileData.fileData);
    }
    return undefined;
  }, [fileData]);

  useEffect(() => {
    if (!fileData && firebaseFileUrl && !firestorageUrlLoading && !indexedStorageFirstLoading) {
      // loading spinner safeguard
      setIndexedStorageLoading(true);
      // downloading the file blob
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = () => {
        // loading the file blob
        const blob: Blob = xhr.response as unknown as Blob;
        void documentDb.documents
          .add({
            id: documentId,
            fileData: blob,
          })
          .finally(() => setIndexedStorageLoading(false));
      };
      xhr.onerror = () => {
        setIndexedStorageLoading(false);
      };
      xhr.open("GET", firebaseFileUrl);
      xhr.send();
    }
  }, [fileData, firebaseFileUrl, indexedStorageFirstLoading, firestorageUrlLoading, documentId]);

  return { data: fileDataUrl, isLoading: firestorageUrlLoading || indexedStorageFirstLoading || indexedStorageLoading };
};

export const useDocumentChatSessionCache = () => {
  const [sessionId] = useDocumentChatSessionId();
  return useAppStateStore((state) => (sessionId ? state.sessionCache[sessionId] : state.sessionCache["NEW"]));
};

export const useUsedAICalls = () => useLocalStorage("USED_AI_CALLS", 0);

const evaluateToHide = ({
  progressData,
  documentDetails,
}: {
  progressData?: DocumentProgress;
  documentDetails?: DocumentDetailsSchema;
}): boolean => {
  if (documentDetails?.processingStatus === "NOT_PROCESSED") return false;
  if (documentDetails?.processingStatus === "UNDER_PROCESSING") return false;
  if (documentDetails?.processingStatus === "PROCESSED") return true;
  if (
    progressData?.error === true ||
    progressData?.processing === false ||
    (progressData?.percentage && progressData.percentage >= 99.5)
  )
    return true;

  return !!(progressData?.percentage && progressData.percentage >= 99.5);
};

export const useDocumentProcessing = () => {
  const currentDocId = useCurrentDocumentTabId();
  const progressData = useDocumentProgress(currentDocId);
  const { data: enity } = useCurrentDocumentTabEntity();
  const { data: document, refetch } = useCurrentDocumentTabEntity();

  useEffect(() => {
    if (
      progressData?.percentage &&
      progressData.percentage >= 99.5 &&
      document?.documentDetails.processingStatus === "UNDER_PROCESSING"
    ) {
      toast({
        title: `Document ${enity?.name} is processed!`,
        variant: "success",
      });
      void refetch();
    }
  }, [document?.documentDetails.processingStatus, enity?.name, progressData, refetch, currentDocId]);

  const title = useMemo(
    () =>
      !evaluateToHide({ progressData, documentDetails: document?.documentDetails })
        ? `Processing: ${(progressData?.percentage ?? 0).toFixed(0)}%`
        : "Quino",
    [document, progressData],
  );
  const processing = !evaluateToHide({ progressData, documentDetails: document?.documentDetails });

  return { progressData, enity, document, refetch, title, processing };
};

export const useAnyDocumentError = (selectedIds: string[]) => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const isDemoLike = useIsDemoLikePage();
  const isDocumentPage = useIsDocumentPage();
  const queries = useMemo(
    () =>
      selectedIds.map((documentId) => ({
        queryKey: [QUERY_KEYS.DOCUMENT, currentWorkspaceId, documentId],
        queryFn: () => {
          if (isDemoLike && Object.hasOwn(IDS.DOCUMENT, documentId)) {
            return fetchDocumentDataDemo(documentId);
          }
          if (isDemoLike) {
            return fetchTemporaryDocumentData(currentWorkspaceId, documentId);
          }
          return fetchDocumentData(currentWorkspaceId, documentId, isDocumentPage ? "document_view" : "library");
        },
      })),
    [currentWorkspaceId, isDemoLike, isDocumentPage, selectedIds],
  );
  return useQueries({
    queries,
    combine: (results) =>
      results.some((query) => {
        return ["ENCRYPTED_PDF", "EMPTY_PDF_ERROR", "NOT_RECOVERABLE_ERRORS", "NOT_KNOWN_ERROR"].includes(
          query?.data?.documentDetails.documentHealthLevel ?? "",
        );
      }),
  });
};

export const useFileUploadDropzone = ({ multiple = true, noClick = false, onDrop }: DropzoneOptions) => {
  const { data: subscription } = useSubscription();

  const fileSizeLimit = useMemo(() => {
    if (subscription?.restrictions?.uploadedDocumentSizeMb)
      return MEGABYTE * subscription?.restrictions?.uploadedDocumentSizeMb;
    return MEGABYTE * BASE_DOCUMENT_SIZE_LIMIT;
  }, [subscription?.restrictions?.uploadedDocumentSizeMb]);

  const acceptedFormats = useMemo(() => {
    if (subscription?.restrictions?.pdfUploadOnly !== undefined) {
      return ACCEPTED_FORMATS_FREE;
    }
    return ACCEPTED_FORMATS;
  }, [subscription?.restrictions?.pdfUploadOnly]);

  return useDropzone({
    onDrop,
    multiple: multiple,
    noClick: noClick,
    maxSize: fileSizeLimit,
    accept: acceptedFormats,
  });
};
