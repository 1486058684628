import { Link } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { QuinoLogoPurple } from "@/components/v3";
import { useFirebaseUserId } from "@/firebase/hooks";
import { mixpanelTrack } from "@/service/mixpanel";

import MobileAppPicture from "/files/images/mobile-app-image.png";

const MobileDisabled = () => {
  const currentUserId = useFirebaseUserId();

  const handleBtnClick = () => {
    mixpanelTrack("mobile_disabled_notification", { firebaseUserId: currentUserId });
    toast({ title: "Inquiry sent!", variant: "success" });
  };

  return (
    <main className="h-auto w-screen">
      <section className="flex flex-col items-center gap-10 px-4 pt-8">
        <QuinoLogoPurple className="h-[41.29px] w-[144px] bg-transparent" />
        <h4 className="text-center font-jakarta text-h4 font-semibold text-primary-onBg">
          Our mobile app is coming soon. We’re excited to bring you a smarter way to learn!
        </h4>
        <div className="flex w-full flex-col gap-2">
          <Button onClick={handleBtnClick}>Notify me</Button>
          <Link to={"https://quino.ai/"}>
            <Button className="w-full border-none" variant="outline">
              Back to website
            </Button>
          </Link>
        </div>
        <div className="relative z-50">
          <img alt="mobile-app-image" src={MobileAppPicture} />
          <div className="absolute bottom-0 h-[109px] w-full bg-mobile-app-image" />
        </div>
        <div className="absolute -right-5 top-[64%] h-[196px] w-[196px] rounded-full bg-blur-bg/50 blur-[67.5px]" />
      </section>
    </main>
  );
};

export default MobileDisabled;
